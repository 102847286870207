




































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { Core } from "@/store/core";

import moment from "moment";
import { FarmForm } from "@/models/farm";
import { BuffaloForm } from "@/models/buffalo";
import { CoreForm } from "@/models/core";
import { User } from "@/store/user";
import { App } from "@/store/app";

@Component({
  components: {},
  computed: {},
})
export default class Farm extends Vue {
  private farm: FarmForm | any = {};
  private form: BuffaloForm | any = {};
  private response: boolean = false;

  private sex: CoreForm[] | null = null;
  private color: CoreForm[] | null = null;
  private status: CoreForm[] | null = null;
  private froms: CoreForm[] | null = null;
  private gene: any[] | null = null;

  private async created() {
    await this.collingPermission();
    await this.setChoices();
    await this.run();
  }
  private async setChoices() {
    this.sex = await Core.getChoice(`เพศควาย`);
    this.color = await Core.getChoice(`สีควาย`);
    this.status = await Core.getChoice(`สถานะควาย`);
    this.froms = await Core.getChoice(`แหล่งที่มาของควาย`);
    this.gene =  await Core.getHttp(`/user/buffalo/genes`)
  }

  async collingPermission() {
    let user = await User.getUser();
    let userAll = await Core.getHttp(`/api/account/${user.pk}/`);
    if (userAll.is_staff) {
      let profile = await Core.getHttp(
        `/api/profile/${this.$route.query.farmer}/`
      );
      this.farm = await Core.getHttp(`/user/buffalo/farm/${profile.user}/`);
      this.response = true;
    } else {
      let profile = await Core.getHttp(`/user/account/myprofile/${user.pk}/`);
      this.farm = await Core.getHttp(`/user/buffalo/farm/${profile.user}/`);
      this.response = true;
    }
  }

  private async run() {}

  @Watch("form.birthday")
  async onChangeDate(val: string) {
    this.form.age = moment().diff(val, "years", false);
    let month = (moment().diff(val, "months", false) - 12) / 12;
    this.form.age_month = Math.floor(month);
  }

  async frontChange(event: any) {
    let file = await Core.getBase64(event.target.files[0]);
    let frontImage: any = this.$refs.frontImage;
    frontImage.src = file;
    this.form.front_image = file;
  }

  async backChange(event: any) {
    let file = await Core.getBase64(event.target.files[0]);
    let backImage: any = this.$refs.backImage;
    backImage.src = file;
    this.form.back_image = file;
  }

  private async createBuffalo() {
    this.form.farm = this.farm.id;
    let create = await Core.postHttp(`/user/buffalo/buffalo/`, this.form);
    if (create.id) {
      await Core.postHttp(`/api/buffalo/cert/create/`, { buffalo: create.id });
      await Core.postHttp(`/api/buffalo/certreal/create/`, {
        buffalo: create.id,
      });
      await Core.postHttp(`/api/buffalo/evolution/create/`, {
        buffalo: create.id,
      });
      await App.success("บันทึกข้อมูลสำเร็จ");
      await this.$router.go(-1);
    } else {
      await App.error("โปรดระบุข้อมูลให้ครบถ้วน");
    }
  }
}
